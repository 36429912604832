<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner" style="margin: 10px -10px 10px -10px">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 30%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 30%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData()">查询</a-button>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <a-tabs :default-active-key="0" v-model="tabsKey" @change="onTabChange">
          <a-tab-pane :key="0" tab="巡查方案">
            <div class="table-header">
              <h3 class="table-title">{{pageTitle}}</h3>
              <div class="table-btns">
                <a-radio-group :default-value="0" button-style="solid" v-model="tabsChildKey" @change="onChange">
                  <a-radio-button :value="0">
                    当前巡查方案
                  </a-radio-button>
                  <a-radio-button :value="1">
                    历史巡查方案
                  </a-radio-button>
                </a-radio-group>
<!--                <a-button type="primary" @click="showModal()"><a-icon type="plus"></a-icon>新增</a-button>-->
                <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
              </div>
            </div>
            <a-table :columns="tableColumns" :data-source="tableData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
              <span slot="patrolname" slot-scope="text,record">
                <a-button type="link" @click="rowClick(record)">{{text}}</a-button>
              </span>
              <span slot="createtime" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="patrolshift" slot-scope="value">
                <span>{{patrolShifts[value].value}}</span>
              </span>
              <span slot="operation" slot-scope="value, record">
                <a-button type="link" @click="operationClick(record)">详情</a-button>
              </span>
            </a-table>
            <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
          <a-tab-pane :key="1" tab="巡查模板" force-render>
            <div class="table-header">
              <h3 class="table-title">巡查模板</h3>
              <div class="table-btns">
                <a-radio-group :default-value="0" button-style="solid" v-model="tabsChildKey" @change="onChange">
                  <a-radio-button :value="0">
                    当前巡查模板
                  </a-radio-button>
                  <a-radio-button :value="1">
                    历史巡查模板
                  </a-radio-button>
                </a-radio-group>
                <a-icon type="redo" class="after-btn table-refresh" @click="getTableData(true)"></a-icon>
              </div>
            </div>
            <a-table :columns="templateColumns" :data-source="templateData" :rowKey="(record,index)=> index" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
              <span slot="patroltemplatename" slot-scope="text,record">
                <a-button type="link" @click="rowClick1(record)">{{text}}</a-button>
              </span>
              <span slot="createtime" slot-scope="value">
                <a-tooltip :title="value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''">
                  <span>{{value ? moment(value, 'YYYYMMDDHHmmss').format('YYYY-MM-DD HH:mm:ss') : ''}}</span>
                </a-tooltip>
              </span>
              <span slot="patrolshift" slot-scope="value">
                <span>{{patrolShifts[value-1].value}}</span>
              </span>
              <span slot="intervals" slot-scope="value, record">
                <span>{{intervals[value].value}}</span>
              </span>
              <span slot="operation" slot-scope="value, record">
                <a-button type="link" @click="operationClick1(record)">详情</a-button>
              </span>
            </a-table>
            <a-pagination v-model="currentPage1" :total="pagination1.total" :page-size="pagination1.pageSize" show-size-changer @change="pageChange1" @showSizeChange="onShowSizeChange1" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
              <template slot="buildOptionText" slot-scope="props">
                <span>{{ props.value }}条/页</span>
              </template>
            </a-pagination>
          </a-tab-pane>
          <a-tab-pane :key="3" tab="人工巡查线路" force-render>
            <artificial-patrol></artificial-patrol>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </page-container>
</template>
<script>
import { mapState } from 'vuex'
import moment from 'moment'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import pagination from '@/mixins/pagination'
import {
  getPatrolSchemeDataListByCondition,
  getPatrolSchemeListByCondition,
  getPatrolTemplateListByCondition
} from "A/patrol";
import {patrolShifts} from "@/json/wlwhistory";
import artificialPatrol from "./artificialPatrol.vue";
export default {
  name: 'patrolSchemeFile',
  components: {artificialPatrol},
  mixins: [deptselect,pagination],
  props: ['pageType'],
  data() {
    return {
      moment,
      patrolShifts,
      showAdvanced: false,
      queryParams: {
        status:0
      },
      userdepidCascaderSelected: [],
      tableColumns: [
        {
          title: '巡查方案名',
          dataIndex: 'patrolname',
          key: 'patrolname',
          ellipsis: true,
          scopedSlots: { customRender: 'patrolname' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '方案创建时间',
          dataIndex: 'createtime',
          key: 'createtime',
          ellipsis: true,
          scopedSlots: { customRender: 'createtime' },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,
      intervals:[
        {"key":0,"value":"一小时一次"},
        {"key":1,"value":"两小时一次"},
        {"key":2,"value":"四小时一次"}
      ],
      templateColumns: [
        {
          title: '巡查模板名',
          dataIndex: 'patroltemplatename',
          key: 'patroltemplatename',
          ellipsis: true,
          scopedSlots: { customRender: 'patroltemplatename' },
        },
        {
          title: '班次',
          dataIndex: 'patrolshift',
          key: 'patrolshift',
          ellipsis: true,
          scopedSlots: { customRender: 'patrolshift' },
        },
        {
          title: '巡查模板时段',
          dataIndex: 'timerange',
          key: 'timerange',
          ellipsis: true,
          scopedSlots: { customRender: 'timerange' },
        },
        {
          title: '巡查时间间隔',
          dataIndex: 'intervals',
          key: 'intervals',
          ellipsis: true,
          scopedSlots: { customRender: 'intervals' },
        },
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '地址',
          dataIndex: 'address',
          key: 'address',
          ellipsis: true,
        },
        {
          title: '模板创建时间',
          dataIndex: 'createtime',
          key: 'createtime',
          ellipsis: true,
          scopedSlots: { customRender: 'createtime' },
        },
        {
          title: '操作',
          key: 'operation',
          align: 'center',
          width: 70,
          scopedSlots: { customRender: 'operation' }
        },
      ],
      templateData:[],
      dataIndex:0,
      tabsKey:0,
      tabsChildKey:0,
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    pageTitle() {
      return '巡查方案';
    },
    breadcrumb() {
      return [
        {
          name: '工作查阅与报告',
          path: ''
        },
        {
          name: this.pageTitle,
          path: ''
        },
      ]
    },
  },
  watch: {
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.tabsKey=0
      this.tabsChildKey=0
      this.getTableData(true);
      this.initDeptOptionsAll();
    },
    resetQueryParams() {
      this.queryAreaSelected = [];
      this.queryInstallDate = [];
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
    },
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
        this.currentPage1 =1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.tabsKey==0?this.currentPage:this.currentPage1,
        pagesize: this.tabsKey==0?this.pagination.pageSize:this.pagination1.pageSize
      };
      getPatrolSchemeListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      })
      getPatrolTemplateListByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.templateData = res.item;
          for(let i=0;i<this.templateData.length;i++){
            this.templateData[i].timerange=moment.unix(this.templateData[i].timerange.split("-")[0]/1000).format("HH:mm")+"-"+moment.unix(this.templateData[i].timerange.split("-")[1]/1000).format("HH:mm")
          }
          this.pagination1.total = res.count;
        }
      })
    },
    onTabChange(e){
      this.queryParams.status=0
      this.tabsKey=e
      this.tabsChildKey=0
      this.getTableData(true,this.tabsKey)
    },
    onChange(e){
      this.queryParams.status=e.target.value
      this.tabsChildKey=e.target.value
      this.dataIndex=e.target.value==0?0:1
      this.getTableData(true,this.dataIndex)
    },
    showModal() {
      this.$router.push({
        name: 'patrolScheme',
        params: {
          showType:'detail',
          modalTitle:"工作查阅"
        }
      })
    },
    operationClick(record) {
      getPatrolSchemeDataListByCondition({patrolschemeid:record.patrolschemeid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.schemeData=res.item
          this.$router.push({
            name: 'patrolScheme',
            params: {
              scheme: record,
              schemeData:this.schemeData,
              showType:'detail',
              modalTitle:"工作查阅"
            }
          })
        }
      })
    },
    rowClick(record) {
      getPatrolSchemeDataListByCondition({patrolschemeid:record.patrolschemeid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.schemeData=res.item
          this.$router.push({
            name: 'patrolScheme',
            params: {
              scheme: record,
              schemeData:this.schemeData,
              showType:'detail',
              modalTitle:"工作查阅"
            }
          })
        }
      })
    },
    showModal1(monitorpointnum,patrolshift,patroltemplateid) {
      if(this.dataIndex==0){
        this.$router.push({
          name: 'patrolTemplate',
          params: {
            monitorpointnum:monitorpointnum,
            patrolshift:patrolshift,
            patrolrecordid: "",
            showType:'detail',
            modalTitle:"工作查阅"
          }
        })
      }else{
        this.$router.push({
          name: 'patrolTemplate',
          params: {
            monitorpointnum:monitorpointnum,
            patrolshift:patrolshift,
            patroltemplateid:patroltemplateid,
            patrolrecordid: "",
            showType:'detail',
            modalTitle:"工作查阅"
          }
        })
      }
    },
    operationClick1(record) {
      this.showModal1(record.monitorpointnum,record.patrolshift,record.patroltemplateid)
    },
    rowClick1(record) {
      this.showModal1(record.monitorpointnum,record.patrolshift,record.patroltemplateid)
    },
  }
}
</script>
<style lang="scss" scoped>
</style>